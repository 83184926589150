import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/react";

import { breakpoints } from "../styles/layouts";

import ActivityCard from "../components/activity-card";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allWpActivity(
        sort: { fields: [date], order: DESC},
        limit: 3
      ) {
        edges {
          node {
            id
            uri
            title
            content
            excerpt
            link
            date
            activityCustomField {
              date
              venue
              qualifications
              durationTime
              attendance
            }
            featuredImage {
              node {
                id
                mediaItemUrl
                mediaDetails {
                  file
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div css={ActivityArticlesStyle}>
      {data.allWpActivity.edges.map(({ node }, i) => (
        <ActivityCard key={i} node={node} />
      ))}
    </div>
  )
}

const ActivityArticlesStyle = css`
  margin: 0 -1.5rem;
  display: flex;
  flex-wrap: nowrap;

  @media ${breakpoints.sm} {
    margin: 0 -1rem;
  }

  @media ${breakpoints.xs} {
    margin: 0 0;

    flex-direction: column;
    flex-wrap: nowrap;
  }
`;
