import React from "react";

import Layout from "../layouts/default";
import SEO from "../components/seo";
import Activity from "../components/activity";

import PageHeader from "../components/page-header";
import { Section, Container } from "../styles/layouts";

const ActivityPage = () => {
  return (
    <Layout>
      <SEO title="アクティビティ" description="FC Assort のアクティビティです" />
      <PageHeader title="Activity" />
      <main>
        <Section>
          <Container>
            <Activity />
          </Container>
        </Section>
      </main>
    </Layout>
  );
}

export default ActivityPage;
